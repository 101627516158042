<template>
  <div class="container">
    <div class="box-title" style="display:flex;justify-content: space-between">
      <h4>
        <i class="fal fa-comment-alt-exclamation"></i>
        ຂໍ້ມູນຄຳຕິຊົມພະນັກງານ
        <span class="countTotal"></span>
      </h4>
      <vs-button success style="margin-bottom: 8px;" @click="downloadExcel">
        download excel
      </vs-button>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາຕາມຈຳນວນດາວ:</label>
              <div class="field">
                <div class="control">
                  <model-list-select
                      :list="starCount"
                      placeholder="ເລືອກຈຳນວນດາວ"
                      option-text="name"
                      v-model="selectStar"
                      option-value="id"
                      selectedItem="name">
                  </model-list-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ເລືອກສາຂາ:</label>
            <div class="field">
              <div class="control">
                <model-list-select
                    :list="branches"
                    v-model="selectedBranch"
                    placeholder="ເລືອກສາຂາກ່ອນ"
                    option-text="name"
                    option-value="id"
                    selectedItem="name">
                </model-list-select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຈາກວັນທີ:</label>
            <date-picker
                type="date"
                :clearable="false"
                name="start_date"
                v-model="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">ຫາວັນທີ:</label>
            <date-picker
                type="date"
                :clearable="false"
                name="end_date"
                v-model="end_date"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຄະແນນ</vs-th>
            <vs-th>ຮູບໂປຟາຍ</vs-th>
            <vs-th>ຊື່ພະນັກງານ</vs-th>
            <vs-th>ອີເມວ</vs-th>
            <vs-th>ເບີໂທລະສັບ</vs-th>
            <vs-th>ສາຂາ</vs-th>
            <vs-th>ຈຳນວນຄຳຕິຊົມ</vs-th>
            <vs-th>ເບິຶ່ງຄຳຕິຊົມ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item,index) in userBranch" :key="index">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>
              <div class="Stars" :style="styleRating(item.feedback_with_rating?item.feedback_with_rating.average:0)"
                   aria-label="Rating of this product is 2.3 out of 5."></div>
              <span
                  :class="checkAverage(item.feedback_with_rating?item.feedback_with_rating.average:0)">
                  <i class="fas fa-engine-warning"></i>
              </span>
            </vs-td>
            <vs-td>
              <div v-if="item && item.user_profile">
                <vs-avatar class="icon-user" size="35">
                  <img :src="item.user_profile.image_url" alt="">
                </vs-avatar>
              </div>
              <div v-else>
                <vs-avatar class="icon-user" size="35">
                  <i class="fal fa-user"></i>
                </vs-avatar>
              </div>
            </vs-td>
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>{{ item.email }}</vs-td>
            <vs-td>{{ item.user_tel }}</vs-td>
            <vs-td>{{ item.user_branches[0].branch.name }}</vs-td>
            <vs-td>
              <div class="feedback-icon" v-if="item.feedbacks_count !== 0">
                {{ item.feedbacks_count }} ຄັ້ງ
              </div>
              <div v-else style="margin-left: 8px">
                <i class="fal fa-minus"></i>
              </div>
            </vs-td>
            <vs-td>
              <vs-button
                  v-if="item.feedbacks_count !== 0"
                  @click="viewUserFeedBack(item.id)"
                  style="width: 30px;height: 30px;;font-size: 14px;"
                  circle primary icon flat>
                <i class="fas fa-eye"></i>
              </vs-button>
              <div v-else style="margin-left: 12px;">
                <i class="fal fa-minus"></i>
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :offset="offset"
              @paginate="FetchData()"
          ></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>

    </div>
  </div>
</template>

<script>
import pagination from "@/Plugins/Paginations/Pagination";
import Loading from "@views/Loading";
import {ModelListSelect} from 'vue-search-select';
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  name: "Index",
  components: {
    pagination,
    Loading,
    ModelListSelect,
    DatePicker
  },
  data() {
    return {
      start_date: new Date(),
      end_date: new Date(),
      isLoading: false,
      branches: [],
      selectStar: "",
      starCount: [
        {
          id: '',
          name: "ທັງໝົດ"
        },
        {
          id: 0,
          name: "0 ດາວ"
        },
        {
          id: 1,
          name: "1 ດາວ"
        },
        {
          id: 2,
          name: "2 ດາວ"
        },
        {
          id: 3,
          name: "3 ດາວ"
        },
        {
          id: 4,
          name: "4 ດາວ"
        },
        {
          id: 5,
          name: "5 ດາວ"
        }
      ],
      userBranch: [],
      selectedBranch: "",
      // Pagination
      offset: 10,
      count: 0,
      pagination: {},
      per_page: 10,
    }
  },
  methods: {
    downloadExcel() {
      this.$axios.get(`excel-branch`, {
        params: {
          model_type: "user",
          download: "excel",
          startDate: moment(this.start_date).format('YYYY-MM-DD'),
          endDate: moment(this.end_date).format('YYYY-MM-DD'),
        }
      }).then((res) => {
        const excelData = res.data.data.download_url;
        const a = document.createElement('a')
        a.href = excelData;
        a.download = excelData.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      });
    },
    checkAverage(average) {
      if (average === 0) return 'none-feedback';
      if (average <= 2) return 'bad-feedback';
      return 'good-feedback'
    },
    styleRating(star) {
      return `--rating:${star};`
    },
    FetchListBranches() {
      this.$http.get('v1/listing/branches?type=&include=excluded').then((res) => {
        this.branches = res.data;
        this.branches.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },
    FetchData() {
      this.isLoading = true;
      this.$axios.get(`users-branch`, {
        params: {
          branch_id: this.selectedBranch,
          page: this.pagination.current_page,
          startDate: moment(this.start_date).format('YYYY-MM-DD'),
          endDate: moment(this.end_date).format('YYYY-MM-DD'),
          per_page: this.per_page,
          star: this.selectStar,
        }
      }).then((res) => {
        this.userBranch = res.data.data.data;
        this.isLoading = false;
        let paginateKey = res.data.data.pagination;
        paginateKey['last_page'] = paginateKey['total_pages'];
        delete paginateKey['total_pages'];
        this.pagination = paginateKey;
        this.count = this.pagination.total;
      }).catch((error) => {
        this.isLoading = false;
        console.log(error)
      })
    },
    viewUserFeedBack(id) {
      const urlTab = this.$router.resolve({
        name: "feedback-user.view",
        params: {
          id: id,
        }
      })
      window.open(urlTab.href)
    }
  },
  watch: {
    'selectedBranch': function () {
      this.FetchData();
    },
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
    'selectStar': function () {
      this.FetchData();
    }
  },
  created() {
    this.FetchListBranches();
    this.FetchData();
  }
}
</script>


<style lang="scss" scoped>
.icon-user {
  i {
    color: #9b9b9b;
    font-size: 16px;
  }
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


</style>
